;(function($) {

// ---------- APP START
window.App = {

  config: {
    scrollOffset: 150
  },

  init: function() {
    App.initMenu();
    App.initTimelineStreak();
    App.initAnchors();
    App.initPictureSize();
    App.initAccordion();
    App.initLightBox();
    App.initForms();
    App.initPopupGie();
  },

  load: function() {
  },

  initMenu: function() {
    var $headerNav = $('.header-nav');
    $('.js-nav-toggler').click(function() {
      $headerNav.toggleClass('is-open');
    });
  },

  initTimelineStreak: function() {
    var bodyh = $('body').height();
    var $shape = $('.timeline-streak .shape');
    if ($shape.length) {
      var h = parseInt(bodyh - $shape.offset().top);
      $shape.css('height', h);
    }
  },

  initAnchors: function() {
    var elems = $('.anchor');
    elems.on('click', function(e) {
      var el = $($(this).attr('href'));
      var pos = el.offset().top - App.config.scrollOffset;
      $(window).scrollTop(pos);
      e.preventDefault();
    });
  },

  initPictureSize: function() {
    $('picture').each(function() {
      if ($(this).find('[data-size]').length) {
        var size = $(this).find('[data-size]').data('size').replace('B', 'o');
        if ($(this).parents('figure.m--no-size').length == 0 && size != '0 Ko') {
          $('<div class="picture-size" />', {html: '<span>Poids de l\'image : </span>' + size}).insertAfter(this);
        }
      }
    });
  },

  initAccordion: function() {
    var $accordion = $('.js-accordion');
    $accordion.on('click', '.js-button', function() {
      $(this).parents('.accordion').toggleClass('active');
    });
  },

  initLightBox: function() {
    if ($('[data-lb-src]').length) {
      var $lightbox = $('<div class="lightbox">').appendTo('body');

      var closeLightBox = function() {
        $lightbox.removeClass('is-open');
      };
      $lightbox.on('click', closeLightBox);

      $('body').on('click', '[data-lb-src]', function(e) {
        var $img = $('<img/>').on('load', function() {
          $lightbox.html($img);
          $lightbox.addClass('is-open');
        });
        $img.attr('src', $(this).data('lb-src'));
      }).on('keydown', function(e) {
        if (e.keyCode === 27) closeLightBox();
      });
    }
  },

  initPopupGie: function() {
    var hours = 24;
    var now = new Date().getTime();
    var setupTime = localStorage.getItem('setupTime');
    if (setupTime == null) {
      App.localStorage('setupTime', now);
    } else {
        if(now - setupTime > hours*60*60*1000) {
            localStorage.clear();
            localStorage.setItem('setupTime', now);
        }
    }
		
		//if(App.localStorage('alertgie') != 'clicked') {
			window.setTimeout(function(){$('body').addClass("alertgie");}, 1600);
		//}
		
		$('.js-popup-gie').on('click touch', function(e) {
			App.localStorage('alertgie', 'clicked');
			$('body').removeClass('alertgie');
		});

  },
	
	localStorage: function(key, value) {
    if (localStorage == null) {
      return console.log('Local storage not supported!');
    } else {
      try {
        if (typeof value != 'undefined') {
          localStorage.setItem(key, value);
          result = value;
        } else {
          result = (value === null) ? localStorage.removeItem(key) :
            localStorage.getItem(key);
        }
        return result;
      } catch(err) {
        private_browsing_error = 'Unable to store local data. Are you using Private Browsing?';
       // $exceed.test(err) ? alert(private_browsing_error) : throwerror(err);
      }
    }
  },

  initForms: function() {
    $('.sv-form [name=token]').val($('meta[name=refresh]').attr('content'));
  },
};
// ---------- APP END

// Init app
$(document).ready(App.init);

})(Zepto);

// ---------- Matomo Code
var _paq = window._paq = window._paq || [];

_paq.push([function() {
  var self = this;
  function getOriginalVisitorCookieTimeout() {
    var now = new Date(),
    nowTs = Math.round(now.getTime() / 1000),
    visitorInfo = self.getVisitorInfo();
    var createTs = parseInt(visitorInfo[2]);
    var cookieTimeout = 33696000; // 13 mois en secondes
    var originalTimeout = createTs + cookieTimeout - nowTs;
    return originalTimeout;
  }
  this.setVisitorCookieTimeout( getOriginalVisitorCookieTimeout() );
}]);

_paq.push(['trackPageView']);
_paq.push(['enableLinkTracking']);
(function() {
  var u="//www.kairos-jourdain.com/matomo/";
  _paq.push(['setTrackerUrl', u+'matomo.php']);
  _paq.push(['setSiteId', '1']);
  var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
  g.type='text/javascript'; g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
})();
// ---------- End Matomo Code
